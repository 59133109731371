

const Header = () => {
    return (
        <div className="ui blue raised center aligned segment" style={{marginBottom:"25px"}}>
              <h2 className="ui header">Contact Manager</h2>
        </div>
    );
}

export default Header;